<template>
<v-container class="pl-6">
  <v-row class="d-flex align-center ma-0 pa-2">
    <div style="text-align: center; background-color: #53c6c6; width: 28px; height: 28px;border-radius: 4px;">
      <v-icon color="#fff"> mdi-timetable </v-icon>
    </div>
    <span class="ml-3 text-h6">项目功能设置</span>
  </v-row>

  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1">项目审批</span>
    <v-switch v-model="settings.itemShenpi" @change="onChange"></v-switch>
  </v-row>
  <v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后,可以对项目的创建,状态变更、业务申请进行审批管控 </span>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1">项目外部联系人</span>
    <v-switch v-model="settings.itemOutMember" @change="onChange"></v-switch>
  </v-row>
	<v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，可以将企业外部需要了解或参与协助的人员加入到项目下 </span>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1">项目关注者</span>
    <v-switch v-model="settings.itemFollower" @change="onChange"></v-switch>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1">里程碑</span>
    <v-switch v-model="settings.itemMilestone" @change="onChange"></v-switch>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1">项目自动完成</span>
    <v-switch v-model="settings.itemAutoComplete" @change="onChange"></v-switch>
  </v-row>
	<v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，当项目进度大于等于100%时，系统自动更新项目状态为已完成 </span>
  </v-row>

</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from "vuex";

export default {
  name: 'ItemSettingTab',
  data() {
    return {
      gettedSetting: {},
      initSetting: {
        itemShenpi: true, //项目审批
        itemOutMember: true, //项目外部联系人
        itemFollower: true, //项目关注者
        itemMilestone: true, //里程碑
        itemAutoComplete: true, //项目自动完成
      },
    };
  },
  computed: {
    settings: {
      get() {
        let val = {
          ...this.initSetting,
          ...this.gettedSetting,
        };
        return val;
      },
      set(newValue) {
      }
    }
  },
  mounted() {
    this.getSettingCall({
      "st_category_id": 3, //tb_item
      "st_category_type": 101 //for this
    }).then(res => {
      if (res) {
        this.gettedSetting = res;
      }
    });
  },
  methods: {
    ...mapActions('app', ['getSettingCall', 'setSettingCall']),
    onChange() {
      this.setSettingCall({
        st_category_id: 3, //tb_item
        st_category_type: 101, //for this
        st_value: this.settings
      }, ).then(res => {
      });
    },
  }
}
</script>

<style scoped>
</style>
